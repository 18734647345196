var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "920px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [_vm._v("복화 동의")]),
        _c("div", { staticClass: "content_box" }, [
          _vm._m(0),
          _c("p", { staticClass: "mt10" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.agreeYn,
                  expression: "agreeYn",
                },
              ],
              attrs: {
                type: "checkbox",
                id: "agreea1",
                name: "agree",
                "true-value": "Y",
                "false-value": "N",
              },
              domProps: {
                checked: Array.isArray(_vm.agreeYn)
                  ? _vm._i(_vm.agreeYn, null) > -1
                  : _vm._q(_vm.agreeYn, "Y"),
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.agreeYn,
                    $$el = $event.target,
                    $$c = $$el.checked ? "Y" : "N"
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.agreeYn = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.agreeYn = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.agreeYn = $$c
                  }
                },
              },
            }),
            _c(
              "label",
              { staticClass: "color_black", attrs: { for: "agreea1" } },
              [
                _vm._v(
                  " 주의사항을 충분히 인지하고 동의서를 고려해운으로 발송함. "
                ),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "text_center mt10" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goModify()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX060P011.007")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("selectFunc")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX060P011.008")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", { staticClass: "tbl_col" }, [
      _c("tbody", [
        _c("tr", [
          _vm._v(
            " 1. 신청인은 다음 각호의 조건에 동의하는 조건으로 귀사를 통하여 수입하여 수령한 컨테이너의 국내로부터의 수출 운송에의 복화(귀사를 통하여 수입한 화물 적출 후 공컨테이너를 귀사에 반납하지 않고 수출화물 선적을 위해 해당 컨테이너를 재사용하는 행위) 사용을 신청할 권리를 갖게됨을"
          ),
          _c("br"),
          _vm._v("확인합니다."),
          _c("br"),
          _c("br"),
          _c("p", [
            _vm._v(
              " 가. 신청인은 본 복화 신청으로 인하여 귀사가 컨테이너에 대한 검사 및 수리를 수행할 수 없게 되었음을 인정한다."
            ),
          ]),
          _c("br"),
          _c("p", [
            _vm._v(
              " 나. 신청인이 복화 신청에 따라 수령한 컨테이너는 화물 운송에 적합한 상태로 인도된 것으로 추정된다. 그리고, 해당 컨테이너가 수출 운송에 투입된 후 손상/결함이 발견된 경우, 해당 손상/결함은 수출 운송을 위한 적입전 또는 적입 과정에서 발생한 것으로 추정된다."
            ),
          ]),
          _c("br"),
          _c("p", [
            _vm._v(
              " 다. 신청인은 위 3.항의 추정은 복화 신청 후 이어지는 수출 운송의"
            ),
            _c("br"),
            _vm._v(
              "국외 수화주에 대하여도 효력이 있음을 보증한다. 만일, 해당 수출 운송 관련 국외 수화주 또는 기타 적하 이해관계인이 해당 컨테이너의 손상/결함을 주장하며 귀사에 화물 클레임을 제기하는 경우, 신청인은 자신의 비용으로 귀사를 면책시켜야 하고, 이와 관련하여 귀사가 입은 일체의 손실"
            ),
            _c("br"),
            _vm._v(
              "(배상금, 비용, 이자를 포함하고 이에 국한되지 않음)을 배상하는 데에 동의한다."
            ),
          ]),
          _c("br"),
          _vm._v(
            " 2. 신청인은 [대리인, 운송사 특정]에게 신청인을 대리하여 귀사에 특정 컨테이너의 복화 사용을 신청할 권리를 부여합니다."
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 3. 신청인이 직접 또는 위 2항 기재 대리인을 통하여 복화 사용을 신청하여 귀사에 화물 운송을 의뢰하는 경우, 해당 개별 운송에는 다른 계약∙법령에 우선하여 위 1.항 기재 각호 조건이 적용됨에 동의합니다. 하지만, 개별 운송에 있어서의 OCF, 부대운임, DEM, DET 등 일체의 운임은 개별 운송계약 조건에 따르게 됨에 동의합니다."
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 4. 신청인은 1) 복화 사용 신청, 복화 신청에 따른 화물 운송에 대한 법률관계는 대한민국 법률에 따르고, 2) 이와 관련하여 분쟁이 발생하는 경우"
          ),
          _c("br"),
          _vm._v("대한상사중재원을 전속관할로 하여 해결하는 데에 동의합니다. "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }